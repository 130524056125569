import React, { useState } from 'react';
import { ChakraProvider, FormControl, FormLabel, Switch, useToast } from '@chakra-ui/react';
import axios from 'axios';

function Maintenance() {
  const [killSwitch, setKillSwitch] = useState(false);
  const toast = useToast();

  const handleToggle = async () => {
    const newKillSwitchState = !killSwitch;
    setKillSwitch(newKillSwitchState);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/killswitch`, { enabled: newKillSwitchState }, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.success) {
        toast({
          title: "Success",
          description: `KillSwitch is now ${newKillSwitchState ? 'ON' : 'OFF'}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update KillSwitch state.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      // Revert toggle state in case of error
      setKillSwitch(!newKillSwitchState);
    }
  };

  return (
    <ChakraProvider>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="kill-switch-toggle" mb="0">
          KILLSWITCH
        </FormLabel>
        <Switch id="kill-switch-toggle" isChecked={killSwitch} onChange={handleToggle} />
      </FormControl>
    </ChakraProvider>
  );
}

export default Maintenance;
