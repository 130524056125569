import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel } from '@chakra-ui/react';
import axios from 'axios';
import './Currency.css';

function Currency() {
    const [currency, setCurrency] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] = useState(false);
    const [isEditCurrencyModalOpen, setIsEditCurrencyModalOpen] = useState(false);
    const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
    const [activeDeactiveCurrency, setActiveDeactiveCurrency] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteCurrency, setDeleteCurrency] = useState(null);
    const toast = useToast();

    // New states for form data
    const [newCurrency, setNewCurrency] = useState({
        currencyName: '',
        currencyCode: '',
        withdrawalFee: 0.0,
        isActive: true
    });

    // Updated state for editing currency
    const [editingCurrency, setEditingCurrency] = useState({
        currencyName: '',
        currencyCode: '',
        withdrawalFee: 0.0
    });

    useEffect(() => {
        fetchCurrency();
    }, []);

    const fetchCurrency = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-currencies`, {
                credentials: 'include', // to include cookies
                headers: {
                    'Content-Type': 'application/json',
                    // Include the Authorization header with the bearer token
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                // Filter out currencies marked as deleted before setting the state
                const activeCurrencies = response.data.data.filter(currency => !currency.isDeleted);
                setCurrency(activeCurrencies);
            } else {
                toast({
                    title: "Failed to fetch currencies.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching currency",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };




    const validateCurrency = (currency) => {
        if (!currency.currencyName.trim()) {
            toast({ title: "currency name is required", status: "error", duration: 5000, isClosable: true });
            return false;
        }
        if (!currency.currencyCode.trim()) {
            toast({ title: "currency symbol is required", status: "error", duration: 5000, isClosable: true });
            return false;
        }
        return true;
    };

    // Handle changes in the form inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCurrency({ ...newCurrency, [name]: value });
    };

    const handleSubmitNewCurrency = async () => {
        const currencyData = {
            ...newCurrency,
            completion_criteria: { Criteria: parseInt(newCurrency.completion_criteria_value, 10) }
        };

        if (!validateCurrency(currencyData)) return;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/currencies/add`, currencyData, {
                credentials: 'include', // to include cookies
                headers: {
                    'Content-Type': 'application/json',
                    // Include the Authorization header with the bearer token
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchCurrency(); // Assuming this function refetches the currency list
            setIsAddCurrencyModalOpen(false);
            toast({
                title: "New currency added.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error adding new currency",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };


    const handleEditCurrency = (currency) => {
        setEditingCurrency(currency);
        setIsEditCurrencyModalOpen(true);
    };

    // // Handle changes in the edit form inputs
    // const handleEditInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setEditingCurrency({ ...editingCurrency, [name]: value });
    // };

    const handleSubmitEditCurrency = async () => {
        const updatedCurrencyData = {
            ...editingCurrency,
            completion_criteria: { Criteria: parseInt(editingCurrency.completion_criteria_value, 10) }
        };

        if (!validateCurrency(updatedCurrencyData)) return;

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/currency/edit/${editingCurrency.currencyId}`, updatedCurrencyData, {
                credentials: 'include', // to include cookies
                headers: {
                    'Content-Type': 'application/json',
                    // Include the Authorization header with the bearer token
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchCurrency(); // Assuming this function refetches the currency list
            setIsEditCurrencyModalOpen(false);
            toast({
                title: "Currency updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating currency",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleActivateDeactivateClick = (currency) => {
        setActiveDeactiveCurrency(currency);
        setIsActivateDeactivateModalOpen(true);
    };

    const handleSubmitActivateDeactivateCurrency = async () => {
        const adminId = localStorage.getItem('adminId');

        if (!adminId) {
            toast({
                title: "Error",
                description: "Admin data is missing.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return; // Exit the function if adminId is null
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/currency/activate-deactivate/${activeDeactiveCurrency.currencyId}`, {
                isActive: !activeDeactiveCurrency.isActive,
                adminId: adminId,
            }, {
                credentials: 'include', // to include cookies
                headers: {
                    'Content-Type': 'application/json',
                    // Include the Authorization header with the bearer token
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchCurrency(); // Refresh the currency list
            setIsActivateDeactivateModalOpen(false);
            toast({
                title: `Currency ${activeDeactiveCurrency.isActive ? "deactivated" : "activated"}.`,
                description: response.data.message,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error activating/deactivating currency",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleDeleteClick = (currency) => {
        setDeleteCurrency(currency);
        setIsDeleteModalOpen(true);
    };

    const handleSubmitDeleteCurrency = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/currency/delete/${deleteCurrency.currencyId}`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
              });
            if (response.data.success) {
                fetchCurrency(); // Refresh the list to exclude the soft-deleted currency
                setIsDeleteModalOpen(false);
                toast({
                    title: "Currency deleted.",
                    description: response.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                throw new Error(response.data.message);
            }
        } catch (err) {
            console.log(err);
            toast({
                title: "Error deleting currency",
                description: err.toString(),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <div className="App">
                <div className="currency-management-container">
                    <div className="search-bar-container d-flex align-items-center justify-content-center">
                        <Input
                            type="text"
                            className="search-bar"
                            placeholder="Search currency..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                        />
                    </div>

                    <br></br>
                    {/* Add Currency buttons */}
                    <div className="bulk-actions">
                        <Button colorScheme="blue" onClick={() => setIsAddCurrencyModalOpen(true)}>➕ Add Currency</Button>
                    </div>
                    <br></br>

                    {/* Add Currency Modal */}
                    <Modal isOpen={isAddCurrencyModalOpen} onClose={() => setIsAddCurrencyModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Add New Currency</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <FormControl>
                                    <FormLabel>Currency Name</FormLabel>
                                    <Input name="currencyName" value={newCurrency.currencyName} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Currency Code</FormLabel>
                                    <Input name="currencyCode" value={newCurrency.currencyCode} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl mt={4}>
                                    <FormLabel>Withdrawal Fee (Native Token)</FormLabel>
                                    <Input name="withdrawalFee" value={newCurrency.withdrawalFee} onChange={handleInputChange} />
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSubmitNewCurrency}>
                                    Save
                                </Button>
                                <Button onClick={() => setIsAddCurrencyModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Edit Currency Modal */}
                    <Modal isOpen={isEditCurrencyModalOpen} onClose={() => setIsEditCurrencyModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit Currency (ID: {editingCurrency?.currencyId})</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <FormControl>
                                    <FormLabel>Currency Name</FormLabel>
                                    <Input
                                        name="currencyName"
                                        value={editingCurrency?.currencyName}
                                        onChange={(e) => setEditingCurrency({ ...editingCurrency, currencyName: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Currency Symbol</FormLabel>
                                    <Input
                                        name="currencyCode"
                                        value={editingCurrency?.currencyCode}
                                        onChange={(e) => setEditingCurrency({ ...editingCurrency, currencyCode: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Withdrawal Fee (Native Token)</FormLabel>
                                    <Input
                                        name="withdrawalFee"
                                        value={editingCurrency?.withdrawalFee}
                                        onChange={(e) => setEditingCurrency({ ...editingCurrency, withdrawalFee: e.target.value })}
                                    />
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSubmitEditCurrency}>
                                    Save Changes
                                </Button>
                                <Button onClick={() => setIsEditCurrencyModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Activate/Deactivate Currency Modal */}
                    <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{activeDeactiveCurrency?.isActive ? "Deactivate" : "Activate"} Currency</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to {activeDeactiveCurrency?.isActive ? "deactivate" : "activate"} this currency?
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateCurrency}>
                                    Yes
                                </Button>
                                <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>No</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    {/* Delete Currency Modal */}
                    <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Delete Currency</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to delete this Currency?
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={handleSubmitDeleteCurrency}>
                                    Delete
                                </Button>
                                <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <div className="table-container mx-auto overflow-auto border rounded">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Currency ID</th>
                                    <th>Currency Name</th>
                                    <th>Currency Symbol</th>
                                    <th>Withdrawal Fee</th>
                                    <th>Is Active</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currency
                                    .filter((currency) => currency.currencyName.toLowerCase().includes(searchQuery))
                                    .map((currency) => (
                                        <tr key={currency.currencyId}>
                                            <td>{currency.currencyId}</td>
                                            <td>{currency.currencyName}</td>
                                            <td>{currency.currencyCode}</td>
                                            <td>{currency.withdrawalFee} {currency.currencyCode}</td>
                                            <td>{currency.isActive ? 'Yes' : 'No'}</td>
                                            <td>
                                                <div className="action-buttons">
                                                    <Button colorScheme="blue" onClick={() => handleEditCurrency(currency)}>Edit</Button>
                                                    <Button colorScheme="yellow" onClick={() => handleActivateDeactivateClick(currency)}>{currency.isActive ? 'Deactivate' : 'Activate'}</Button>
                                                    <Button colorScheme="red" onClick={() => handleDeleteClick(currency)}>Delete</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ChakraProvider>
    );
}

export default Currency;