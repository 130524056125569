import React, { useState, useEffect } from 'react';
import { ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Select } from '@chakra-ui/react';
import axios from 'axios';
import './QuestManagement.css';

function QuestManagement() {
  const [quests, setQuests] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddQuestModalOpen, setIsAddQuestModalOpen] = useState(false);
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isEditQuestModalOpen, setIsEditQuestModalOpen] = useState(false);
  const [editingSelectedCategory, setEditingSelectedCategory] = useState('');
  const [isActivateDeactivateModalOpen, setIsActivateDeactivateModalOpen] = useState(false);
  const [activeDeactiveQuest, setActiveDeactiveQuest] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteQuest, setDeleteQuest] = useState(null);
  const toast = useToast();

  // New states for form data
  const [newQuest, setNewQuest] = useState({
    questName: '',
    questDescription: '',
    difficultyLevel: 'easy', // default value
    category: '',
    rewardPoints: 0,
    completionCriteria_value: 0,
    isActive: true
  });

  // Updated state for editing quest
  const [editingQuest, setEditingQuest] = useState({
    questId: null,
    questName: '',
    questDescription: '',
    difficultyLevel: '',
    category: '',
    rewardPoints: 0,
    completionCriteria_value: 0,
  });

  // Function to reset the newQuest state to its default values
  const resetNewQuestState = () => {
    setNewQuest({
      questName: '',
      questDescription: '',
      difficultyLevel: 'easy', // default value
      category: '',
      rewardPoints: 0,
      completionCriteria_value: 0,
      isActive: true
    });
  };

  // Fetch quests from the server
  const fetchQuests = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-quests`, {
        method: 'GET',
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setQuests(response.data.data);
    } catch (err) {
      console.error("Error fetching quests", err);
      toast({
        title: 'Failed to fetch quests',
        description: err.response?.data?.message || 'An error occurred while fetching quests.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch categories from the server
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-categories`, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCategories(response.data.data);
    } catch (err) {
      console.error("Error fetching categories", err);
      toast({
        title: 'Failed to fetch categories',
        description: err.response?.data?.message || 'An error occurred while fetching categories.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchQuests();
    fetchCategories();
  }, []);

  // Handle adding a new category
  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      toast({ title: "Category name is required", status: "error", duration: 5000, isClosable: true });
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/categories/add`, { categoryName: newCategoryName.trim() }, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.success) {
        setNewCategoryName('');
        // Assume setIsAddCategoryModalOpen and fetchCategories are defined elsewhere in your component
        setIsAddCategoryModalOpen(false);
        fetchCategories(); // Refresh categories list
        toast({
          title: "New category added.",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      const errorMessage = err.response && err.response.data.message ? err.response.data.message : "Error adding new category";
      console.error("Error adding new category", err);
      toast({
        title: "Failed to add new category.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setNewQuest({ ...newQuest, categoryName: category });
  };


  const validateQuest = (quest) => {
    if (!quest.questName.trim()) {
      toast({ title: "Quest name is required", status: "error", duration: 5000, isClosable: true });
      return false;
    }
    if (!quest.questDescription.trim()) {
      toast({ title: "Quest description is required", status: "error", duration: 5000, isClosable: true });
      return false;
    }
    if (quest.rewardPoints === '' || isNaN(quest.rewardPoints)) {
      toast({ title: "Valid reward points are required", status: "error", duration: 5000, isClosable: true });
      return false;
    }

    if (!quest.categoryName || !quest.categoryName.trim()) {
      toast({ title: "Category is required", status: "error", duration: 5000, isClosable: true });
      return false;
    }

    if (!quest.completionCriteria ||
      isNaN(Number(quest.completionCriteria.Criteria)) ||
      quest.completionCriteria.Criteria <= 0) {
      toast({ title: "Valid completion criteria are required", status: "error", duration: 5000, isClosable: true });
      return false;
    }

    const rewardPoints = parseInt(quest.rewardPoints, 10);
    if (isNaN(rewardPoints) || rewardPoints <= 0) {
      toast({ title: "Reward points must be a positive number", status: "error", duration: 5000, isClosable: true });
      return false;
    }
    return true;
  };

  // Handle changes in the form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuest({ ...newQuest, [name]: value });
  };

  const handleSubmitNewQuest = async () => {
    const adminId = localStorage.getItem('adminId');
    if (!adminId) {
      toast({ title: "Admin data is missing", status: "error", duration: 5000, isClosable: true });
      return;
    }

    const questData = {
      ...newQuest,
      completionCriteria: { Criteria: parseInt(newQuest.completionCriteria_value, 10) },
      adminId: adminId,
    };

    if (!validateQuest(questData)) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/quests/add`, questData, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success) {
        fetchQuests(); // Assume this function fetches the list of quests
        setIsAddQuestModalOpen(false); // Assume this function toggles the add quest modal
        toast({
          title: "New quest added.",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        resetNewQuestState();
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      const errorMessage = err.response && err.response.data.message ? err.response.data.message : "Error adding new quest";
      console.error("Error adding new quest", err);
      toast({
        title: "Failed to add new quest.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditQuest = (quest) => {
    setEditingQuest(quest);
    setEditingSelectedCategory(quest.categoryName);
    setIsEditQuestModalOpen(true);
  };

  // Handle changes in the edit form inputs
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingQuest({ ...editingQuest, [name]: value });
  };


  const handleSubmitEditQuest = async () => {
    // Retrieve the adminId from localStorage
    const adminId = localStorage.getItem('adminId');

    // Check if adminId is available
    if (!adminId) {
      console.error('Admin data is missing');
      return;
    }

    const updatedQuestData = {
      ...editingQuest,
      completionCriteria: { Criteria: parseInt(editingQuest.completionCriteria_value, 10) },
      adminId: adminId, // Include adminId in questData
    };

    console.log('updated quest data', updatedQuestData)

    if (!validateQuest(updatedQuestData)) return;

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/quests/edit/${editingQuest.questId}`, updatedQuestData, {
        method: 'GET',
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchQuests();
      setIsEditQuestModalOpen(false);
      toast({
        title: "Quest updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Error updating quest", err);
    }
  };

  const handleActivateDeactivateClick = (quest) => {
    setActiveDeactiveQuest(quest);
    setIsActivateDeactivateModalOpen(true);
  };

  const handleSubmitActivateDeactivateQuest = async (quest) => {
    const newIsActiveStatus = !activeDeactiveQuest.isActive;
    console.log('inside handle submite activate deactivate')
    const adminId = localStorage.getItem('adminId');
    if (!adminId) {
      toast({ title: "Admin ID is missing", status: "error", duration: 5000, isClosable: true });
      return;
    }

    try {
      console.log('inside try')
      console.log("quest.questId: ", quest.questId)
      console.log("quest.questId: ", activeDeactiveQuest.questId)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/quests/activate-deactivate/${activeDeactiveQuest.questId}`,
        {
          isActive: newIsActiveStatus,
          adminId
        }, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
      );
      if (response.data.success) {
        fetchQuests(); // Assume fetchQuests() refreshes the quest list
        setIsActivateDeactivateModalOpen(false);
        toast({
          title: `Quest ${quest.isActive ? "deactivated" : "activated"}`,
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      const errorMessage = err.response && err.response.data.message ? err.response.data.message : "Error activating/deactivating quest";
      console.error("Error activating/deactivating quest", err);
      toast({
        title: "Failed to update quest status",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = (quest) => {
    setDeleteQuest(quest);
    setIsDeleteModalOpen(true);
  };

  const handleSubmitDeleteQuest = async () => {
    // Get adminId from local storage
    const adminId = localStorage.getItem('adminId');

    if (!adminId) {
      console.error('Admin data is null');
      return;
    }

    try {
      // Instead of sending adminId in the request body, you can send it as a query parameter
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/quests/delete/${deleteQuest.questId}?adminId=${adminId}`, {
        credentials: 'include', // to include cookies
        headers: {
          'Content-Type': 'application/json',
          // Include the Authorization header with the bearer token
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchQuests(); // Refresh the list
      setIsDeleteModalOpen(false);
      toast({
        title: "Quest deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Error deleting quest", err);
    }
  };


  return (
    <ChakraProvider>
      <div className="App">
        <div className="quest-management-container">
          <div className="search-bar-container d-flex align-items-center justify-content-center">
            <Input
              type="text"
              className="search-bar"
              placeholder="Search quests..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            />
          </div>

          <br></br>
          {/* Add Quest and Add Category buttons */}
          <div className="bulk-actions">
            <Button colorScheme="blue" onClick={() => setIsAddQuestModalOpen(true)}>➕ Add Quest</Button>
            <Button colorScheme="green" onClick={() => setIsAddCategoryModalOpen(true)}>➕ Add Category</Button>
          </div>
          <br></br>

          {/* Add Quest Modal */}
          <Modal isOpen={isAddQuestModalOpen} onClose={() => {
            setIsAddQuestModalOpen(false);
            resetNewQuestState();
            }}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Quest</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Quest Name</FormLabel>
                  <Input name="questName" value={newQuest.questName} onChange={handleInputChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Quest Description</FormLabel>
                  <Input name="questDescription" value={newQuest.questDescription} onChange={handleInputChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Difficulty Level</FormLabel>
                  <Select name="difficultyLevel" value={newQuest.difficultyLevel} onChange={handleInputChange}>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Category</FormLabel>
                  <Select name="category" value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="" disabled>Select Category</option>
                    {categories.map((category) => (
                      <option key={category.categoryId} value={category.categoryName}>
                        {category.categoryName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Completion Criteria Value</FormLabel>
                  <Input
                    type="number"
                    name="completionCriteria_value"
                    value={newQuest.completionCriteria_value}
                    onChange={handleInputChange}
                  />
                </FormControl>


                <FormControl mt={4}>
                  <FormLabel>Reward Points</FormLabel>
                  <Input name="rewardPoints" type="number" value={newQuest.rewardPoints} onChange={handleInputChange} />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSubmitNewQuest}>
                  Save
                </Button>
                <Button onClick={() => {
                  setIsAddQuestModalOpen(false);
                  resetNewQuestState();
                  }}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Add Category Modal */}
          <Modal isOpen={isAddCategoryModalOpen} onClose={() => setIsAddCategoryModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Category</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Category Name</FormLabel>
                  <Input value={newCategoryName} onChange={(e) => setNewCategoryName(e.target.value)} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleAddCategory}>Save</Button>
                <Button onClick={() => setIsAddCategoryModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Edit Quest Modal */}
          <Modal isOpen={isEditQuestModalOpen} onClose={() => setIsEditQuestModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit Quest (ID: {editingQuest?.questId})</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Quest Name</FormLabel>
                  <Input
                    name="questName"
                    value={editingQuest?.questName}
                    onChange={(e) => setEditingQuest({ ...editingQuest, questName: e.target.value })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Quest Description</FormLabel>
                  <Input name="questDescription" value={editingQuest?.questDescription} onChange={(e) => setEditingQuest({ ...editingQuest, questDescription: e.target.value })} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Difficulty Level</FormLabel>
                  <Select
                    name="difficultyLevel"
                    value={editingQuest?.difficultyLevel}
                    onChange={(e) => setEditingQuest({ ...editingQuest, difficultyLevel: e.target.value })}
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Category</FormLabel>
                  <Select name="category" value={editingSelectedCategory} onChange={(e) => setEditingSelectedCategory(e.target.value)}>
                    <option value="" disabled>Select Category</option>
                    {categories.map((category) => (
                      <option key={category.categoryId} value={category.categoryName}>
                        {category.categoryName}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Completion Criteria Value</FormLabel>
                  <Input
                    type="number"
                    name="completionCriteria_value"
                    value={editingQuest.completionCriteria_value}
                    onChange={handleEditInputChange}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Reward Points</FormLabel>
                  <Input
                    name="rewardPoints"
                    type="number"
                    value={editingQuest?.rewardPoints}
                    onChange={(e) => setEditingQuest({ ...editingQuest, rewardPoints: e.target.value })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSubmitEditQuest}>
                  Save Changes
                </Button>
                <Button onClick={() => setIsEditQuestModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Activate/Deactivate Quest Modal */}
          <Modal isOpen={isActivateDeactivateModalOpen} onClose={() => setIsActivateDeactivateModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{activeDeactiveQuest?.isActive ? "Deactivate" : "Activate"} Quest</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to {activeDeactiveQuest?.isActive ? "deactivate" : "activate"} this quest?
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSubmitActivateDeactivateQuest}>
                  Yes
                </Button>
                <Button onClick={() => setIsActivateDeactivateModalOpen(false)}>No</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* Delete Quest Modal */}
          <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Delete Quest</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to delete this quest?
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={handleSubmitDeleteQuest}>
                  Delete
                </Button>
                <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <div className="table-container mx-auto overflow-auto border rounded">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Quest ID</th>
                  <th>Quest Name</th>
                  <th>Quest Description</th>
                  <th>Difficulty Level</th>
                  <th>Category</th>
                  <th>Reward Points</th>
                  <th>Is Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {quests
                  .filter((quest) => !quest.isDeleted)
                  .filter((quest) => quest.questName.toLowerCase().includes(searchQuery))
                  .map((quest) => (
                    <tr key={quest.questId}>
                      <td>{quest.questId}</td>
                      <td>{quest.questName}</td>
                      <td>{quest.questDescription}</td>
                      <td>{quest.difficultyLevel}</td>
                      <td>{quest.categoryName}</td>
                      <td>{quest.rewardPoints}</td>
                      <td>{quest.isActive ? 'Yes' : 'No'}</td>
                      <td>
                        <div className="action-buttons">
                          <Button colorScheme="blue" onClick={() => handleEditQuest(quest)}>Edit</Button>
                          <Button colorScheme="yellow" onClick={() => handleActivateDeactivateClick(quest)}>{quest.isActive ? 'Deactivate' : 'Activate'}</Button>
                          <Button colorScheme="red" onClick={() => handleDeleteClick(quest)}>Delete</Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default QuestManagement;

