// RegisterForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { Input, Button,  useToast } from '@chakra-ui/react';

function RegisterForm({ onPostRegister, adminRegister }) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useToast();
  
    const handleRegister = async (event) => {
      event.preventDefault();

      // Password validation regex
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  
    if (!passwordRegex.test(password)) {
        toast({
            title: "Error",
            description: "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.",
            status: "error", 
            duration: 9000,
            isClosable: true,
        });
        return;
    }

    if (password !== confirmPassword) {
    toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 9000,
        isClosable: true,
    });
    return;
    }
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/register`, { username, password, email});
  
        if (response.status === 201) {
          toast({
            title: "Registration Successful",
            description: "Please login with your new credentials.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          // Redirect to login or additional logic
          if (onPostRegister) {
            onPostRegister();
          }
        } else {
          const errorData = await response.json();
          toast({
            title: "Registration Failed",
            description: errorData.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        if(error.response.status === 400) {
            toast({
                title: "Error",
                description: 'Username taken. Please enter a different username',
                status: "error",
                duration: 9000,
                isClosable: true,
              });
        } else if(error.response.status === 401) {
            toast({
                title: "Error",
                description: 'Email taken. Please enter a different email.',
                status: "error",
                duration: 9000,
                isClosable: true,
              });
        } else if(error.response.status === 402) {
            toast({
                title: "Error",
                description: 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.',
                status: "error",
                duration: 9000,
                isClosable: true,
              });
        } else {
            toast({
                title: "Error",
                description: 'Failed to register. Please try again later.',
                status: "error",
                duration: 9000,
                isClosable: true,
              });
        }
      }
      onPostRegister();
    };
  
    return (
      <form onSubmit={handleRegister}>
        <Input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          mb={3}
        />
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={3}
        />
        <Input
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={3}
        />
        <Input
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          mb={3}
        />
        <Button colorScheme="green" type="submit">Register</Button>
      </form>
    );
  }
  
  export default RegisterForm;

