import React, { useState, useEffect } from 'react';
import {
    ChakraProvider, Input, useToast, Button, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel
} from '@chakra-ui/react';
import axios from 'axios';
import './Currency.css';

function CurrencyThresholds() {
    const [currency, setCurrency] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditCurrencyModalOpen, setIsEditCurrencyModalOpen] = useState(false);
    const [editingCurrency, setEditingCurrency] = useState({
        currencyName: '',
        currencyCode: '',
        totalThresholdInUsd: 0.0,
        individualThresholdInUsd: 0.0
    });
    const toast = useToast();

    useEffect(() => {
        fetchCurrency();
    }, []);

    const fetchCurrency = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/retrieve-currencies`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                const filteredCurrencies = response.data.data.filter(currency => !currency.isDeleted);
                setCurrency(filteredCurrencies);
            } else {
                toast({
                    title: "Failed to fetch currencies.",
                    description: response.data.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }
        } catch (err) {
            toast({
                title: "Error fetching currency",
                description: err.toString(),
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handleEditCurrency = (currency) => {
        setEditingCurrency(currency);
        setIsEditCurrencyModalOpen(true);
    };

    const handleSubmitEditCurrency = async () => {
        const updatedCurrencyData = {
            totalThresholdInUsd: editingCurrency.totalThresholdInUsd,
            individualThresholdInUsd: editingCurrency.individualThresholdInUsd
        };

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/currency/edit/threshold/${editingCurrency.currencyId}`, updatedCurrencyData, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            fetchCurrency();
            setIsEditCurrencyModalOpen(false);
            toast({
                title: "Currency thresholds updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            const errorMessage = err.response && err.response.data.message ? err.response.data.message : "An unexpected error occurred.";
            toast({
                title: "Error updating currency thresholds",
                description: errorMessage,
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    };

    return (
        <ChakraProvider>
            <div className="App">
                <div className="currency-management-container">
                    <div className="search-bar-container d-flex align-items-center justify-content-center">
                        <Input
                            type="text"
                            className="search-bar"
                            placeholder="Search currency..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                        />
                    </div>
                    <br></br>

                    {/* Edit Currency Modal */}
                    <Modal isOpen={isEditCurrencyModalOpen} onClose={() => setIsEditCurrencyModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Edit Currency Thresholds (ID: {editingCurrency?.currencyId})</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <FormControl>
                                    <FormLabel>Total Threshold (USD)</FormLabel>
                                    <Input
                                        name="totalThresholdInUsd"
                                        value={editingCurrency.totalThresholdInUsd}
                                        onChange={(e) => setEditingCurrency({ ...editingCurrency, totalThresholdInUsd: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Individual Threshold (USD)</FormLabel>
                                    <Input
                                        name="individualThresholdInUsd"
                                        value={editingCurrency.individualThresholdInUsd}
                                        onChange={(e) => setEditingCurrency({ ...editingCurrency, individualThresholdInUsd: e.target.value })}
                                    />
                                </FormControl>
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={handleSubmitEditCurrency}>
                                    Save Changes
                                </Button>
                                <Button onClick={() => setIsEditCurrencyModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                    <div className="table-container mx-auto overflow-auto border rounded">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Currency ID</th>
                                    <th>Currency Name</th>
                                    <th>Currency Symbol</th>
                                    <th>Total Threshold (USD)</th>
                                    <th>Individual Threshold (USD)</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currency
                                    .filter((currency) => currency.currencyName.toLowerCase().includes(searchQuery))
                                    .map((currency) => (
                                        <tr key={currency.currencyId}>
                                            <td>{currency.currencyId}</td>
                                            <td>{currency.currencyName}</td>
                                            <td>{currency.currencyCode}</td>
                                            <td>{currency.totalThresholdInUsd}</td>
                                            <td>{currency.individualThresholdInUsd}</td>
                                            <td>
                                                <Button colorScheme="blue" onClick={() => handleEditCurrency(currency)}>Edit</Button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ChakraProvider>
    );
}

export default CurrencyThresholds;
