import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, Button, useToast } from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import RegisterForm from './RegisterForm'; // Import the RegisterForm
import './Login.css';

function Login() {
  const { setAuthToken } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const message = query.get('message');

    if (message === 'forcedLogout') {
      toast({
        title: "Logged out.",
        description: "You have been logged out because another device has detected a log in.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [location, toast]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, { username, password });
  
      if (response.data.success) {
        setAuthToken(response.data.data.token); // Assuming setAuthToken updates context or state
        const decodedToken = jwtDecode(response.data.data.token);
        localStorage.setItem('adminId', decodedToken.adminId);
        toast({
          title: "Login Successful",
          description: "You are now logged in.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Login Failed",
          description: response.data.error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (err) {
      toast({
        title: "Login Error",
        description: "Invalid username or password.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const handlePostRegister = () => {
    onRegisterClose(); // Close the register modal
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Welcome to $BANG ADMIN</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="password">Password:</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <Button type="submit" colorScheme="blue" width="full">Login</Button>
        </form>
        <Button onClick={onRegisterOpen}>Register</Button>

        {/* Register Modal */}
        <Modal isOpen={isRegisterOpen} onClose={onRegisterClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Register</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RegisterForm onPostRegister={handlePostRegister} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
}

export default Login