// src/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Sidebar.css';

function Sidebar() {
  const { logout } = useAuth(); 
  return (
    <div className="sidebar">
      <nav>
        <h2>Sidebar</h2>
        <ul>
          <li><Link to="/users">Users</Link></li>
          <li><Link to="/quests">Quests</Link></li>
          <li><Link to="/currency">Multi-Currency</Link></li>
          <li><Link to="/report">Report</Link></li>
          <li><Link to="/maintenance">Maintenance</Link></li>
          <li><Link to="/threshold">Threshold</Link></li>
          {/* Additional links */}
        </ul>
      </nav>
      <button onClick={logout}>Logout</button>
    </div>
  );
}

export default Sidebar;
