import React, { createContext, useContext, useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import { io } from 'socket.io-client';

// const AuthContext = createContext(null);

// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState(null);
//   const [socket, setSocket] = useState(null);

//   const updateAuthState = (updates) => {
//     setAuthState(currentState => ({ ...currentState, ...updates }));
//   };

//   const checkTokenExpiration = (token) => {
//     try {
//       const decoded = jwtDecode(token);
//       const currentTime = Date.now() / 1000;
//       if (decoded.exp < currentTime) {
//         localStorage.removeItem('token');
//         return null;
//       }
//       return decoded;
//     } catch (error) {
//       console.error('Error decoding token:', error);
//       localStorage.removeItem('token');
//       return null;
//     }
//   };

//   const setAuthToken = (token) => {
//     const newSocket = io(`${process.env.REACT_APP_API_URL}`);
//     if (token) {
//       localStorage.setItem('token', token);
//       const decoded = jwtDecode(token);
//       setAuthState(decoded); // assuming jwtDecode works correctly
//       newSocket.on('connect', () => {
//         console.log(`Socket connected: ${newSocket.id}`);
//         console.log('Socket token:', token);
//         newSocket.emit('registerJWT', token);
//         console.log(`JWT token emitted for socket ${newSocket.id}`);
//       });
//       setSocket(newSocket);
//     } else {
//       localStorage.removeItem('token');
//       setAuthState(null);
//       if (socket) {
//         socket.disconnect();
//       }
//     }
//   };

//   const logout = () => {
//     localStorage.removeItem('token');
//     setAuthState(null);
//   };

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       const decoded = checkTokenExpiration(token);
//       if (decoded) {
//         setAuthState(decoded);
//       } else {
//         localStorage.removeItem('token');
//       }
//     }
//   }, []);

//   return (
//     <AuthContext.Provider value={{ authState, setAuthToken, logout, updateAuthState }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => useContext(AuthContext);

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(null);
  const [socket, setSocket] = useState(null);
  const toast = useToast();

  const updateAuthState = (updates) => {
    setAuthState(currentState => ({ ...currentState, ...updates }));
  };

  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('token');
        return null;
      }
      return decoded;
    } catch (error) {
      console.error('Error decoding token:', error);
      localStorage.removeItem('token');
      return null;
    }
  };

  const initializeSocket = (token) => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`);
    newSocket.on('connect', () => {
      // console.log(`Socket connected: ${newSocket.id}`);
      // console.log('Socket token:', token);
      newSocket.emit('registerJWT', token);
      // console.log(`JWT token emitted for socket ${newSocket.id}`);
    });
    newSocket.on('forcedLogout', (data) => {
      // console.log('forcedLogout event received:', data);
      localStorage.removeItem('token'); // Remove the token
      setAuthState(null);
      window.location.href = '/?message=forcedLogout'; // Redirect to login page with query parameter
    });
    setSocket(newSocket);
  };

  const setAuthToken = (token) => {
    if (token) {
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      setAuthState(decoded); // assuming jwtDecode works correctly
      initializeSocket(token);
    } else {
      localStorage.removeItem('token');
      setAuthState(null);
      if (socket) {
        socket.disconnect();
      }
    }
  };

  const logout = () => {
    const token = localStorage.getItem('token');
    if (socket && token) {
      // console.log(`Emitting logout event with token: ${token}`);
      return new Promise((resolve, reject) => {
        socket.emit('logout', token, (response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve();
          }
        });
      })
      .then(() => {
        localStorage.removeItem('token');
        setAuthState(null);
        if (socket) {
          socket.disconnect();
        }
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
    } else {
      localStorage.removeItem('token');
      setAuthState(null);
      if (socket) {
        socket.disconnect();
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = checkTokenExpiration(token);
      if (decoded) {
        setAuthState(decoded);
        initializeSocket(token); // Re-initialize socket on page refresh
      } else {
        localStorage.removeItem('token');
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthToken, logout, updateAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
