import React, { useState } from 'react';
import {
    ChakraProvider,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Select,
    useToast,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Box,
    Link
} from '@chakra-ui/react';
import axios from 'axios';
import { Parser } from 'json2csv';

function Report() {
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedGameCode, setSelectedGameCode] = useState(null);
    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        gameType: 'ALL',
        reportType: 'ALL'
    });
    const toast = useToast();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        const { fromDate, toDate } = formData;

        if (!fromDate || !toDate) {
            toast({
                title: "Validation Error",
                description: "Both 'From Date' and 'To Date' must be selected.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const fromDateTime = new Date(fromDate);
        fromDateTime.setUTCHours(0, 0, 0, 0);
        const fromDateString = fromDateTime.toISOString();

        const toDateTime = new Date(toDate);
        toDateTime.setUTCHours(22, 0, 0, 0);
        const toDateString = toDateTime.toISOString();

        if (fromDateTime >= toDateTime) {
            toast({
                title: "Validation Error",
                description: "'From Date' must be earlier than 'To Date'.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/report`, { fromDate: fromDateString, toDate: toDateString }, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.data.success) {
                setReportData(response.data.data);
                setIsSearchModalOpen(false);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.message || "Failed to fetch the report data.",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
    };

    const groupDataByUser = (data) => {
        const groupedData = {};
        data.forEach((item) => {
            if (!groupedData[item.username]) {
                groupedData[item.username] = {
                    totalStake: 0,
                    turnOver: 0,
                    tax: 0,
                    commission: 0,
                    winAmount: 0,
                    jackpotAmount: 0,
                    netWinLoss: 0
                };
            }
            groupedData[item.username].totalStake += parseFloat(item.totalStake) || 0;
            groupedData[item.username].turnOver += parseFloat(item.turnOver) || 0;
            groupedData[item.username].tax += parseFloat(item.tax) || 0;
            groupedData[item.username].commission += parseFloat(item.commission) || 0;
            groupedData[item.username].winAmount += parseFloat(item.winAmount) || 0;
            groupedData[item.username].jackpotAmount += parseFloat(item.jackpotAmount) || 0;
            groupedData[item.username].netWinLoss += (parseFloat(item.winAmount) || 0) - (parseFloat(item.totalStake) || 0);
        });
        return groupedData;
    };

    const groupDataByProvider = (data, username) => {
        const filteredData = data.filter(item => item.username === username);
        const groupedData = {};
        filteredData.forEach((item) => {
            if (!groupedData[item.providerName]) {
                groupedData[item.providerName] = {
                    totalStake: 0,
                    turnOver: 0,
                    tax: 0,
                    commission: 0,
                    winAmount: 0,
                    jackpotAmount: 0,
                    netWinLoss: 0
                };
            }
            groupedData[item.providerName].totalStake += parseFloat(item.totalStake) || 0;
            groupedData[item.providerName].turnOver += parseFloat(item.turnOver) || 0;
            groupedData[item.providerName].tax += parseFloat(item.tax) || 0;
            groupedData[item.providerName].commission += parseFloat(item.commission) || 0;
            groupedData[item.providerName].winAmount += parseFloat(item.winAmount) || 0;
            groupedData[item.providerName].jackpotAmount += parseFloat(item.jackpotAmount) || 0;
            groupedData[item.providerName].netWinLoss += (parseFloat(item.winAmount) || 0) - (parseFloat(item.totalStake) || 0);
        });
        return groupedData;
    };

    const groupDataByGameCode = (data, providerName) => {
        const filteredData = data.filter(item => item.providerName === providerName);
        const groupedData = {};
        filteredData.forEach((item) => {
            if (!groupedData[item.gameCode]) {
                groupedData[item.gameCode] = {
                    totalStake: 0,
                    turnOver: 0,
                    tax: 0,
                    commission: 0,
                    winAmount: 0,
                    jackpotAmount: 0,
                    netWinLoss: 0
                };
            }
            groupedData[item.gameCode].totalStake += parseFloat(item.totalStake) || 0;
            groupedData[item.gameCode].turnOver += parseFloat(item.turnOver) || 0;
            groupedData[item.gameCode].tax += parseFloat(item.tax) || 0;
            groupedData[item.gameCode].commission += parseFloat(item.commission) || 0;
            groupedData[item.gameCode].winAmount += parseFloat(item.winAmount) || 0;
            groupedData[item.gameCode].jackpotAmount += parseFloat(item.jackpotAmount) || 0;
            groupedData[item.gameCode].netWinLoss += (parseFloat(item.winAmount) || 0) - (parseFloat(item.totalStake) || 0);
        });
        return groupedData;
    };

    const groupDataByTransaction = (data, gameCode) => {
        const filteredData = data.filter(item => item.gameCode === gameCode);
        const groupedData = filteredData.map((item) => ({
            transactionId: item.transactionId,
            totalStake: parseFloat(item.totalStake) || 0,
            turnOver: parseFloat(item.turnOver) || 0,
            tax: parseFloat(item.tax) || 0,
            commission: parseFloat(item.commission) || 0,
            winAmount: parseFloat(item.winAmount) || 0,
            jackpotAmount: parseFloat(item.jackpotAmount) || 0,
            netWinLoss: (parseFloat(item.winAmount) || 0) - (parseFloat(item.totalStake) || 0),
            settlementTime: item.settlementTime
        }));
        return groupedData;
    };

    const handleUserClick = (username) => {
        setSelectedUser(username);
        setSelectedProvider(null); // Reset provider selection
        setSelectedGameCode(null); // Reset game code selection
    };

    const handleProviderClick = (providerName) => {
        setSelectedProvider(providerName);
        setSelectedGameCode(null); // Reset game code selection
    };

    const handleGameCodeClick = (gameCode) => {
        setSelectedGameCode(gameCode);
    };

    const handleBreadcrumbClick = (level) => {
        if (level === 'user') {
            setSelectedUser(null);
            setSelectedProvider(null);
            setSelectedGameCode(null);
        } else if (level === 'provider') {
            setSelectedProvider(null);
            setSelectedGameCode(null);
        } else if (level === 'gameCode') {
            setSelectedGameCode(null);
        }
    };

    const groupedData = selectedGameCode
        ? groupDataByTransaction(reportData, selectedGameCode)
        : selectedProvider
            ? groupDataByGameCode(reportData, selectedProvider)
            : selectedUser
                ? groupDataByProvider(reportData, selectedUser)
                : groupDataByUser(reportData);

    const calculateSummary = (data) => {
        const summary = {
            totalStake: 0,
            turnOver: 0,
            tax: 0,
            commission: 0,
            winAmount: 0,
            jackpotAmount: 0,
            netWinLoss: 0
        };

        if (data instanceof Array) {
            data.forEach(item => {
                summary.totalStake += item.totalStake;
                summary.turnOver += item.turnOver;
                summary.tax += item.tax;
                summary.commission += item.commission;
                summary.winAmount += item.winAmount;
                summary.jackpotAmount += item.jackpotAmount;
                summary.netWinLoss += item.netWinLoss;
            });
        } else {
            Object.values(data).forEach(item => {
                summary.totalStake += item.totalStake;
                summary.turnOver += item.turnOver;
                summary.tax += item.tax;
                summary.commission += item.commission;
                summary.winAmount += item.winAmount;
                summary.jackpotAmount += item.jackpotAmount;
                summary.netWinLoss += item.netWinLoss;
            });
        }

        return summary;
    };

    const summaryData = calculateSummary(groupedData);

    const downloadCSV = () => {
        const json2csvParser = new Parser();
        const csv = json2csvParser.parse(reportData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <ChakraProvider>
            {!reportData.length && (
                <Button colorScheme="blue" onClick={() => setIsSearchModalOpen(true)}>Search</Button>
            )}

            <Modal isOpen={isSearchModalOpen} onClose={() => setIsSearchModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate Report</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>From Date</FormLabel>
                            <Input
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>To Date</FormLabel>
                            <Input
                                type="date"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Game Type</FormLabel>
                            <Select name="gameType" value={formData.gameType} onChange={handleInputChange}>
                                <option value="ALL">ALL</option>
                            </Select>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Report Type</FormLabel>
                            <Select name="reportType" value={formData.reportType} onChange={handleInputChange}>
                                <option value="ALL">ALL</option>
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Generate
                        </Button>
                        <Button onClick={() => setIsSearchModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box mb={4}>
                {selectedUser && (
                    <Box mb={2}>
                        <Link color="blue" onClick={() => handleBreadcrumbClick('user')}>{selectedUser}</Link>
                        {selectedProvider && (
                            <>
                                {' >> '}
                                <Link color="blue" onClick={() => handleBreadcrumbClick('provider')}>{selectedProvider}</Link>
                            </>
                        )}
                        {selectedGameCode && (
                            <>
                                {' >> '}
                                <Link color="blue" onClick={() => handleBreadcrumbClick('gameCode')}>{selectedGameCode}</Link>
                            </>
                        )}
                    </Box>
                )}
            </Box>

            <Box overflowX="auto">
                <Table variant="striped" colorScheme="teal" size="sm">
                    <Thead bg="gray.200">
                        <Tr>
                            {selectedGameCode ? (
                                <>
                                    <Th>Index</Th>
                                    <Th>Total Stake</Th>
                                    <Th>Turnover</Th>
                                    <Th>Tax</Th>
                                    <Th>Commission</Th>
                                    <Th>Win Amount</Th>
                                    <Th>Jackpot Amount</Th>
                                    <Th>Net Win/Loss</Th>
                                    <Th>Settlement Time</Th>
                                </>
                            ) : selectedProvider ? (
                                <>
                                    <Th>Game Code</Th>
                                    <Th>Total Stake</Th>
                                    <Th>Turnover</Th>
                                    <Th>Tax</Th>
                                    <Th>Commission</Th>
                                    <Th>Win Amount</Th>
                                    <Th>Jackpot Amount</Th>
                                    <Th>Net Win/Loss</Th>
                                </>
                            ) : selectedUser ? (
                                <>
                                    <Th>Provider</Th>
                                    <Th>Total Stake</Th>
                                    <Th>Turnover</Th>
                                    <Th>Tax</Th>
                                    <Th>Commission</Th>
                                    <Th>Win Amount</Th>
                                    <Th>Jackpot Amount</Th>
                                    <Th>Net Win/Loss</Th>
                                </>
                            ) : (
                                <>
                                    <Th>User</Th>
                                    <Th>Total Stake</Th>
                                    <Th>Turnover</Th>
                                    <Th>Tax</Th>
                                    <Th>Commission</Th>
                                    <Th>Win Amount</Th>
                                    <Th>Jackpot Amount</Th>
                                    <Th>Net Win/Loss</Th>
                                </>
                            )}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {groupedData instanceof Array ? (
                            groupedData.map((item, index) => (
                                <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{item.totalStake.toFixed(2)}</Td>
                                    <Td>{item.turnOver.toFixed(2)}</Td>
                                    <Td>{item.tax.toFixed(2)}</Td>
                                    <Td>{item.commission.toFixed(2)}</Td>
                                    <Td>{item.winAmount.toFixed(2)}</Td>
                                    <Td>{item.jackpotAmount.toFixed(2)}</Td>
                                    <Td>{item.netWinLoss.toFixed(2)}</Td>
                                    <Td>{new Date(item.settlementTime).toLocaleString()}</Td>
                                </Tr>
                            ))
                        ) : (
                            Object.keys(groupedData).map((key, index) => (
                                <Tr key={index}>
                                    {selectedGameCode ? (
                                        <Td>{key}</Td>
                                    ) : selectedProvider ? (
                                        <Td onClick={() => handleGameCodeClick(key)} style={{ cursor: 'pointer', color: 'blue' }}>{key}</Td>
                                    ) : selectedUser ? (
                                        <Td onClick={() => handleProviderClick(key)} style={{ cursor: 'pointer', color: 'blue' }}>{key}</Td>
                                    ) : (
                                        <Td onClick={() => handleUserClick(key)} style={{ cursor: 'pointer', color: 'blue' }}>{key}</Td>
                                    )}
                                    <Td>{groupedData[key].totalStake.toFixed(2)}</Td>
                                    <Td>{groupedData[key].turnOver.toFixed(2)}</Td>
                                    <Td>{groupedData[key].tax.toFixed(2)}</Td>
                                    <Td>{groupedData[key].commission.toFixed(2)}</Td>
                                    <Td>{groupedData[key].winAmount.toFixed(2)}</Td>
                                    <Td>{groupedData[key].jackpotAmount.toFixed(2)}</Td>
                                    <Td>{groupedData[key].netWinLoss.toFixed(2)}</Td>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Td>{selectedGameCode ? "Total" : "Summary"}</Td>
                            <Td>{summaryData.totalStake.toFixed(2)}</Td>
                            <Td>{summaryData.turnOver.toFixed(2)}</Td>
                            <Td>{summaryData.tax.toFixed(2)}</Td>
                            <Td>{summaryData.commission.toFixed(2)}</Td>
                            <Td>{summaryData.winAmount.toFixed(2)}</Td>
                            <Td>{summaryData.jackpotAmount.toFixed(2)}</Td>
                            <Td>{summaryData.netWinLoss.toFixed(2)}</Td>
                            {selectedGameCode && <Td></Td>}
                        </Tr>
                    </Tfoot>
                </Table>
            </Box>

            <Button colorScheme="green" onClick={downloadCSV}>Download CSV</Button>
        </ChakraProvider>
    );
}

export default Report;
